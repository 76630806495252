/* Features Section Title */
.FeaturesTitle {
  text-align: center;
  font-size: 2rem; /* Large font for emphasis */
  color: #282c34; /* Dark color for contrast */
  margin-bottom: 40px; /* Space below the title */
}

/* Main Content Wrapper */
.App_content {
  padding: 20px; /* Padding for spacing */
}

/* Modules Grid */
.Modules {
  display: grid; /* Using grid layout for better responsiveness */
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Responsive columns */
  gap: 20px; /* Space between items */
}

/* Individual Module */
.Module {
  background-color: #fff; /* White background for modules */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Inner padding */
  text-align: center; /* Centered text */
  transition: transform 0.3s; /* Animation for hover effect */
}

.Module:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.Module img {
  width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
}

.Module h3 {
  margin: 15px 0 10px; /* Margin for heading */
  font-size: 1.5rem; /* Heading size */
}

.Module p {
  color: #666; /* Gray text for description */
  font-size: 1rem; /* Font size for description */
}

.Module a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold link */
}

.Module a:hover {
  text-decoration: underline; /* Underline on hover */
}
