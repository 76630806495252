/* General reset and box sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Landing Main Wrapper */
.LandingMain {
  display: flex;
  /* height: 100vh;  */

  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 200px; */
  text-align: center;
  max-width: 100%;
}

/* Hero Section Styling */
.HeroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px); /* Full viewport height minus navbar height */
  width: 100%;
  flex-grow: 1;
  background-color: #f4f4f4; /* Light background for contrast */
  padding: 0 20px; /* Padding for smaller screens */
}

.Headline {
  font-size: 3.5rem; /* Adjust for visibility */
  color: #333;
}

.Subheadline {
  font-size: 2.5rem; /* Adjust for visibility */
  color: #666;
  margin: 10px 0;
}

.cta-button {
  background-color: #4caf50; /* Green button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* .App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  position: relative;
  top: 0;
  
} */

/* .saashederTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
} */

/* .saasLogoDiv {
  font-size: 24px;
  font-weight: bold;
} */

/* .saasMenuActionDiv {
  flex-grow: 1;
} */

/* .App_content{

} */

/* Who Is This For Section */
.WhoIsThisFor {
  padding: 40px 20px; /* Padding for spacing */
  background-color: #f4f4f4; /* Light background to match the landing */
  text-align: center; /* Centered text */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 20px auto; /* Margin for spacing from other sections */
  max-width: 800px; /* Maximum width for readability */
}

.WhoIsThisFor h2 {
  font-size: 2rem; /* Large heading size */
  color: #282c34; /* Dark color for contrast */
  margin-bottom: 20px; /* Space below the heading */
}

.WhoIsThisFor p {
  font-size: 1.2rem; /* Slightly larger text for the description */
  color: #666; /* Gray color for the description text */
  margin-bottom: 20px; /* Space below the paragraph */
}

.AudienceList {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
}

.AudienceList li {
  font-size: 1rem; /* Normal font size for list items */
  color: #444; /* Darker gray for readability */
  margin: 10px 0; /* Space between list items */
  text-align: left; /* Align text to the left for better readability */
}

.AudienceList strong {
  color: #282c34; /* Bold text color */
}

.Modules {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.Modules div {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Modules img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* footer {
  background-color: #282c34;
  color: white;
  padding: 20px 0;

  width: 100%;
} */

.why-choose-us {
  background-color: #f4f4f9; /* Light background */
  padding: 50px 20px;
  text-align: center;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  color: #333; /* Darker color for header */
  margin-bottom: 20px;
  font-family: "Roboto Slab", serif;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  background-color: #ffffff; /* White background for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.feature-item h3 {
  font-size: 1.25rem;
  color: #4caf50; /* Color accent */
  font-family: "Dancing Script", cursive; /* Unique font for titles */
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
  color: #666; /* Gray color for text */
  line-height: 1.6;
}

@media (max-width: 768px) {
  .why-choose-us h2 {
    font-size: 2rem;
  }

  .feature-item h3 {
    font-size: 1.15rem;
  }

  .feature-item p {
    font-size: 0.9rem;
  }
}
