.no-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #282c34;
}

.no-page-heading {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.no-page-message {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
