.ContactMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  padding: 20px;
  background-color: #f4f4f4; /* Light background for contrast */
  text-align: center; /* Center text for headings */
}

.ContactForm {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Set a max-width for the form */
  width: 100%;
  background-color: #f4f4f4; /* Light background for contrast */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow */
}

.ContactForm div {
  margin-bottom: 15px; /* Space between inputs */
}

.ContactForm label {
  margin-bottom: 5px; /* Space between label and input */
}

.ContactForm input,
.ContactForm textarea {
  padding: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  width: 100%; /* Full width */
}

.ContactForm button {
  background-color: #282c34; /* Dark background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

.ContactForm button:hover {
  background-color: #50565c; /* Darker shade on hover */
}
