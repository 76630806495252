.App-footer {
  background-color: #282c34; /* Dark background */
  color: #ffffff; /* White text */
  text-align: center; /* Centered text */
  padding: 20px; /* Add padding */
  font-size: 1rem; /* Font size */
  bottom: 0; /* Fixed at the bottom */
  width: 100%; /* Full width */
}

.footer-links {
  margin-top: 10px; /* Space between copyright and links */
}

.footer-links a {
  color: #61dafb; /* Light blue color for links */
  margin: 0 5px; /* Space between links */
  text-decoration: none; /* Remove underline */
}

.footer-links a:hover {
  text-decoration: underline; /* Underline on hover */
}

.social-media-icons {
  margin-top: 10px; /* Space above icons */
}

.social-media-icons img {
  width: 24px; /* Icon size */
  height: 24px; /* Icon size */
  margin: 0 5px; /* Space between icons */
}
