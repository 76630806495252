.APP-Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px; /* Set the navbar height */
  background-color: #282c34;
  z-index: 10; /* Ensure the navbar is above other elements */
}

/* Navbar styling */
/* .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #282c34;
  z-index: 10;
} */

.saasHeaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  /* background-color: #fff; */
  color: #ffffff;
}

.saasLogoDiv {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

/* Adjustments for Menu Links */
.saasNavLinks .ant-menu-item {
  color: #ffffff; /* White text for menu items */
}

.saasNavLinks .ant-menu {
  display: flex;
  gap: 20px;
  background: none;
  border-bottom: none;
}

.saasMenuActionDiv .ant-btn-primary {
  background-color: #1890ff;
  border: none;
}
